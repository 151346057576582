import { Form, Input, Modal, message } from "antd";
import { useChangeUserPasswordMutation } from "features/userManagementTable/userManagementAPI";

import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
`;

const FormFieldsContainer = styled.div`
  max-width: 400px;
`;

const StyledUserDiv = styled.div`
  margin-bottom: 16px;
`;

export default function ChangePasswordModal({ title, open, onOk, onCancel, selectedUser }) {
  const [form] = Form.useForm();
  const [changeUserPassword] = useChangeUserPasswordMutation();
  const onSubmit = async () => {
    const values = await form.validateFields();
    if (values.password !== values.confirmNewPassword) {
      form.setFields([
        {
          name: "confirmNewPassword",
          errors: ["Passwords do not match"],
        },
      ]);
      return;
    }
    const result = await changeUserPassword({
      userId: selectedUser?.id,
      password: values.password,
    });

    if ("data" in result) {
      form.resetFields();
      message.success("Password changed successfully!");
    }
    if ("error" in result) {
      message.error("Failed to change password.");
    }
    onOk();
  };
  const onModalCancel = async () => {
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onModalCancel}
      width={800}
    >
      <StyledForm
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
      >
        <StyledUserDiv>
          {selectedUser?.firstName} {selectedUser?.lastName}
        </StyledUserDiv>
        <FormFieldsContainer>
          <Form.Item
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Password must be at least 8 characters with at least 1 upper case, 1 lower case, 1 number, and 1 special character",
              },
              { required: true },
            ]}
          >
            <Input.Password placeholder="Enter New Password..." />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            label="Confirm New Password"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="Confirm New Password..." />
          </Form.Item>
        </FormFieldsContainer>
      </StyledForm>
    </Modal>
  );
}
