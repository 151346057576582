import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, message } from "antd";
import { DEFAULT_LOGIN_REDIRECT_SCREEN } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { resetStateAction } from "common/redux/resetStateAction";
import { useLazyFetchMeQuery, useLoginMutation } from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk, updateTokens } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 300px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const BAD_REDIRECT_ROUTES = ["/login", "/signup", "/change-password"];

function LocalLoginForm() {
  const { isWhiteLabeled, displayName } = useWhiteLabel();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const [fetchMe] = useLazyFetchMeQuery();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);

  // Reset state when user navigates to login page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToDestination = async () => {
    // Fetch the optional defaultLoginRedirectScreen field from /me
    // to see if there is a specific page a user should see
    // on login
    const { data: me } = await fetchMe();
    if ("error" in me) {
      form.setFields(formatError(me.error));
      setErrorMessage("Something went wrong");
    }

    // @ts-ignore
    const defaultLoginRedirectScreen = `/${
      me?.defaultLoginRedirectScreen || DEFAULT_LOGIN_REDIRECT_SCREEN
    }`;

    // Firstly, we look at returnTo object
    // Then, at defaultLoginRedirectScreen from fetchMe request
    // Lastly, we fall for this default value
    const returnTo =
      location.state?.returnTo && BAD_REDIRECT_ROUTES.indexOf(location.state.returnTo) === -1
        ? location.state.returnTo
        : defaultLoginRedirectScreen;

    navigate(returnTo);
  };

  const onFinish = async (values) => {
    const { email, password } = values;

    const result = await login({ email, password });
    if ("data" in result) {
      dispatch(updateTokens(result.data));
      await redirectToDestination();
      message.success("Logged in successfully.", 2);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      setErrorMessage("Invalid username or password");
    }
  };

  return (
    <>
      <h3>Log in to Your {isWhiteLabeled ? displayName : "Aktos"} Account</h3>
      <StyledForm
        onFinish={onFinish}
        className="login-form"
        initialValues={{
          email: "",
          password: "",
        }}
        form={form}
      >
        <Form.Item
          name="email"
          hasFeedback
          rules={[
            { required: true, message: "Please enter email" },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Enter email" data-testid="email" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
            data-testid="password"
          />
        </Form.Item>
        <Form.Item>
          <StyledButton
            type="primary"
            htmlType="submit"
            className="login-form-button"
            data-testid="login-button"
            loading={isLoading}
          >
            Log in
          </StyledButton>
        </Form.Item>
        {errorMessage && (
          <Form.Item>
            <Alert message={errorMessage} type="error" />
          </Form.Item>
        )}
      </StyledForm>

      <StyledDiv>
        <a href="/signup">Have a client code? Sign up</a>
      </StyledDiv>
    </>
  );
}

export default LocalLoginForm;
