import { Row } from "antd";
import TableFilter from "components/tableFilter/tableFilter";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import {
  useFetchAgencyWorkflowStates,
  useFetchBackendConstantsQuery,
} from "features/home/agencyPortal/homeAPI";
import { useFetchCommunicationSequencesQuery } from "features/sequencesTable/communicationSequenceAPI";
import CommunicationTasksTable from "pages/communications/communicationTaskTable";
import { useState } from "react";

const initialFilterState = {
  scheduledDate: null,
  communicationSequenceIds: [],
  communicationMethodIds: [],
  communicationTypes: [],
  communicationTaskStatuses: ["scheduled"],
  accountStatusesInclude: [],
  accountStatusesExclude: [],
};

function CommunicationTaskListTab() {
  // React state to track the filters
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: communicationSequences } = useFetchCommunicationSequencesQuery();
  const { data: communicationMethods } = useFetchCommunicationMethodsQuery();
  const { data: accountWorkflowStates } = useFetchAgencyWorkflowStates();

  const [filterStates, setFilterStates] = useState(initialFilterState);

  function setFilterProperty(name, value) {
    setFilterStates((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  // This is the structure of the filters that will be displayed on the page
  const filteredStructure = [
    {
      title: "Scheduled Date",
      name: "scheduledDate",
      type: "date",
      onChange: (event) => setFilterProperty("scheduledDate", event),
    },
    {
      title: "Communication Sequence",
      name: "communicationSequenceIds",
      type: "dropdown",
      dropdown: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("communicationSequenceIds", event);
        },
        options: communicationSequences?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Communication Sequence...",
      },
    },
    {
      title: "Communication Method",
      name: "communicationMethodIds",
      type: "dropdown",
      dropdown: {
        mode: "multiple",
        onChange: (event) => {
          setFilterProperty("communicationMethodIds", event);
        },
        options: communicationMethods?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Communication Method...",
      },
    },
    {
      title: "Communication Type",
      name: "communicationTypes",
      type: "dropdown",
      dropdown: {
        mode: "multiple",
        onChange: (event) => setFilterProperty("communicationTypes", event),
        options: constants?.communicationMediums?.map((d) => ({
          label: d.display,
          value: d.value,
        })),
        placeholder: "Select Communication Type...",
      },
    },
    {
      title: "Communication Task Status",
      name: "communicationTaskStatuses",
      type: "dropdown",
      initialValue: ["scheduled"],
      dropdown: {
        mode: "multiple",
        options: constants?.communicationTaskStatuses?.map((d) => ({
          label: d.display,
          value: d.value,
        })),
        onChange: (event) => {
          setFilterProperty("communicationTaskStatuses", event);
        },
        placeholder: "Select Status...",
      },
    },
    {
      title: "Account Status",
      name: "accountStatuses",
      type: "include/exclude",
      include: {
        mode: "multiple",
        onChange: (event) => setFilterProperty("accountStatusesInclude", event),
        options: accountWorkflowStates?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Account Status...",
      },
      exclude: {
        mode: "multiple",
        onChange: (event) => setFilterProperty("accountStatusesExclude", event),
        options: accountWorkflowStates?.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        placeholder: "Select Account Status...",
      },
    },
  ];

  function convertStateToQueryParams() {
    /**
     * Convert the filter states to query params
     * @returns {
     *  scheduledStartDate: string,
     *  scheduledEndDate: string,
     *  communicationSequenceIds: string[],
     *  communicationMethodIds: string[],
     *  communicationType: string[],
     *  communicationTaskStatuses: string[],
     *  accountStatusesInclude: string[],
     *  accountStatusesExclude: string[],
     * } queryParams
     *
     */
    const queryParams = {};

    if (filterStates.scheduledDate) {
      const [scheduledStartDate, scheduledEndDate] = filterStates.scheduledDate;
      if (scheduledStartDate) {
        queryParams.scheduledStartDate = scheduledStartDate;
      }
      if (scheduledEndDate) {
        queryParams.scheduledEndDate = scheduledEndDate;
      }
    }

    if (filterStates.communicationSequenceIds.length > 0) {
      queryParams.sequenceIds = filterStates.communicationSequenceIds;
    }

    if (filterStates.communicationMethodIds.length > 0) {
      queryParams.communicationMethodIds = filterStates.communicationMethodIds;
    }

    if (filterStates.communicationTypes.length > 0) {
      queryParams.communicationMediums = filterStates.communicationTypes;
    }

    if (filterStates.communicationTaskStatuses.length > 0) {
      queryParams.communicationTaskStatuses = filterStates.communicationTaskStatuses;
    }

    if (filterStates.accountStatusesInclude.length > 0) {
      queryParams.accountStatusesInclude = filterStates.accountStatusesInclude;
    }

    if (filterStates.accountStatusesExclude.length > 0) {
      queryParams.accountStatusesExclude = filterStates.accountStatusesExclude;
    }

    return {
      filters: queryParams,
    };
  }

  function resetFilters() {
    setFilterStates(initialFilterState);
  }

  return (
    <Row wrap={false}>
      <TableFilter filteredStructure={filteredStructure} onReset={() => resetFilters()} />
      {/* NOTE: Looking for suggestion on the best way to debounce this */}
      <CommunicationTasksTable filterParams={convertStateToQueryParams()} />
    </Row>
  );
}
export default CommunicationTaskListTab;
