import api, { transformMultipart, transformRequest, transformResponse } from "services/api";

export const documentsApi = api
  .enhanceEndpoints({ addTagTypes: ["Documents", "DebtorSystemLogs", "Accounts"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getDocuments: build.query({
        query: ({ debtorId }) => `/api/v1/core/debtors/${debtorId}/documents/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Documents", id })),
                { type: "Documents", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Documents', id: 'LIST' }` is invalidated
              [{ type: "Documents", id: "LIST" }],
      }),
      postDocuments: build.mutation({
        query: ({ debtorId, file, ...values }) => ({
          url: `/api/v1/core/debtors/${debtorId}/documents/create/`,
          method: "POST",
          body: transformMultipart(values, file),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Documents-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "Documents", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
          { type: "Accounts", id: "LIST" },
        ],
      }),
      updateDocument: build.mutation({
        query: ({ debtorId, documentId, ...values }) => ({
          url: `/api/v1/core/debtors/${debtorId}/documents/${documentId}/update/`,
          method: "POST",
          body: transformRequest(values),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Documents", id: arg.documentId },
          { type: "Documents", id: "LIST" },
        ],
      }),
      deleteDebtorDocument: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/documents/${payload.documentId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Documents", id: arg.documentId },
          { type: "Documents", id: "LIST" },
        ],
      }),
    }),
  });

export const {
  useGetDocumentsQuery,
  usePostDocumentsMutation,
  useDeleteDebtorDocumentMutation,
  useUpdateDocumentMutation,
} = documentsApi;
