import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { Tooltip, Dropdown, Button, Modal, message } from "antd";
import { COMMUNICATION_TASK_STATUS } from "common/constants";
import { useBulkCancelCommunicationTasksMutation } from "features/communicationTasks/communicationTasksAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";

const { confirm } = Modal;

export function CommunicationTasksBulkActions({ dataSource = [] }) {
  const [cancelCommunicationTasks] = useBulkCancelCommunicationTasksMutation();

  const {
    data: { [PERMISSIONS.COMMUNICATION_TASK__CANCEL]: isCommunicationTaskCancelAuthorized },
  } = useAuthorizations();

  const actionItems = [
    {
      key: "cancel",
      label: "Cancel Communication Task",
      actionHandler: cancelCommunicationTasks,
      isVisible: isCommunicationTaskCancelAuthorized,
      disabled:
        dataSource.length === 0 ||
        // Only show the option if the selected communication tasks are scheduled
        // (not already cancelled, succeeded, or failed) (item.status = COMMUNICATION_TASK_STATUS.SCHEDULED)
        dataSource.some((item) => item.status !== COMMUNICATION_TASK_STATUS.SCHEDULED),
    },
  ];

  const handleOk = (item, key) => {
    confirm({
      title: `Are you sure you want to ${key} these communication tasks?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await item.actionHandler({
          communicationTaskIds: [...dataSource.map((row) => row.id)],
        });

        if ("error" in result) {
          message.error(`Failed to ${key} communication tasks`);
        }

        if ("data" in result) {
          switch (key) {
            case "cancel":
              message.success("Communication tasks successfully cancelled");
              break;
            default:
              message.success(`Communication tasks successfully ${key}ed`);
          }
        }
      },
    });
  };

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items array
    handleOk(item.props, key);
  };

  return (
    <Tooltip title="Perform action on selected items">
      <Dropdown
        menu={{
          items: actionItems,
          onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp),
        }}
        trigger={["click"]}
      >
        <Button
          type="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <MoreOutlined />
        </Button>
      </Dropdown>
    </Tooltip>
  );
}
