import { Form, Input, Select } from "antd";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useFetchFeePlanRuleSetsQuery } from "features/feePlans/feePlansAPI";

const REMITTANCE_OPTIONS = [
  {
    label: "Gross",
    value: "gross",
  },
  {
    label: "Net",
    value: "net",
  },
];

const BILLING_OPTIONS = [
  {
    label: "Gross Accounts Receivable",
    value: "gross",
  },
  {
    label: "Net Accounts Receivable",
    value: "net",
  },
];

const PAYMENT_DELIVERY_METHOD_OPTIONS = [
  {
    label: "Checks",
    value: "checks",
  },
  {
    label: "ACH",
    value: "ach",
  },
];

export default function InvoiceStep() {
  const { data: feePlanRuleSets } = useFetchFeePlanRuleSetsQuery();

  return (
    <>
      <Form.Item name="id" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "feePlanAssignmentRulesetId"]}
        label="Fee Plan Rule Set"
        rules={[{ required: true, message: "Fee plan rule set is required" }]}
      >
        <Select
          options={feePlanRuleSets?.map((ruleSet) => ({ label: ruleSet.name, value: ruleSet.id }))}
        />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "principalRemit", "agency"]}
        label="Agency Principal Payment Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "interestRemit", "agency"]}
        label="Agency Interest Payment Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "feesRemit", "agency"]}
        label="Agency Fees Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "principalRemit", "creditor"]}
        label="Client Principal Payment Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "interestRemit", "creditor"]}
        label="Client Interest Payment Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "feesRemit", "creditor"]}
        label="Creditor Fees Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "adjustments"]}
        label="Invoice Adjustment Remit Type"
        initialValue="net"
        rules={[{ required: true, message: "Remit type is required" }]}
      >
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
      <Form.Item
        name={["invoiceConfig", "billing"]}
        label="Billing"
        tooltip={
          <>
            <p>
              Gross Accounts Receivable - Bill any amount due agency for each invoice separately.
            </p>
            <p>
              Net Accounts Receivable - Deduct any prior invoice amount due agency from next
              remittance.
            </p>
          </>
        }
        initialValue="net"
        rules={[{ required: true, message: "Invoice billing type is required" }]}
      >
        <Select options={BILLING_OPTIONS} />
      </Form.Item>

      <Form.Item
        name={["invoiceConfig", "paymentMethodType"]}
        label="Payment Method"
        tooltip={
          <>
            <p>ACH - Amount due Client to be paid in ACH payments.</p>
            <p>Checks - Amount due Client to be paid via printing checks.</p>
          </>
        }
        initialValue="checks"
        rules={[{ required: true, message: "Invoice payment method type is required" }]}
      >
        <Select options={PAYMENT_DELIVERY_METHOD_OPTIONS} />
      </Form.Item>
      <Form.Item name={["invoiceConfig", "taxRate"]} label="Tax Rate">
        <AktPercentageInput />
      </Form.Item>
      <Form.Item name={["invoiceConfig", "taxRemit"]} label="Invoice Tax Remit Type">
        <Select options={REMITTANCE_OPTIONS} />
      </Form.Item>
    </>
  );
}
