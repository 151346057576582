import api, { transformCursorResponse, transformRequest } from "services/api";

export const communicationTasksApi = api
  .enhanceEndpoints({ addTagTypes: ["CommunicationTasks"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getCommunicationTasksList: build.query({
        query: (payload) => ({
          url: `/api/v1/core/communication/tasks/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: () => [{ type: "CommunicationTasks", id: "LIST" }],
      }),
      bulkCancelCommunicationTasks: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/communication/tasks/bulk-cancel/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: () => [{ type: "CommunicationTasks", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const { useGetCommunicationTasksListQuery, useBulkCancelCommunicationTasksMutation } =
  communicationTasksApi;
