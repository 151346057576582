import { Tabs } from "antd";
import Content from "layouts/agencyPortal/dashboard/content";
import PageLayout from "layouts/agencyPortal/dashboard/pageLayout";
import CommunicationTaskListTab from "pages/communications/communicationTaskListTab";

const items = [
  {
    key: "1",
    label: "Communication Tasks",
    children: <CommunicationTaskListTab />,
  },
];

function CommunicationTaskPage() {
  return (
    <PageLayout>
      <Content>
        <Tabs defaultActiveKey="1" items={items} />
      </Content>
    </PageLayout>
  );
}

export default CommunicationTaskPage;
