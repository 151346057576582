import { Col, Row } from "antd";
import CompanyLogo from "assets/aktos-logo-366x340-nowhitespace.png";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import styled from "styled-components";

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;

function PageLayout({ children }) {
  const { isWhiteLabeled, logo } = useWhiteLabel();

  return (
    <Row className="App">
      <StyledCol>
        <img src={isWhiteLabeled ? logo : CompanyLogo} alt="" width={50} />
        {children}
      </StyledCol>
    </Row>
  );
}

export default PageLayout;
