import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table, message } from "antd";
import config from "app/envConfig";
import { capitalizeFirstLetter } from "common/utils";
import { useUserType } from "features/auth";
import {
  useDeleteDebtorDocumentMutation,
  useGetDocumentsQuery,
} from "features/documentsTable/agencyPortal/documentsAPI";
import UploadDocumentModal from "features/documentsTable/components/uploadDocumentModal";
import {
  useDeleteCreditorPortalDebtorDocumentMutation,
  useGetCreditorPortalDebtorDocumentsQuery,
} from "features/documentsTable/creditorPortal/documentsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

const StyledTable = styled(Table)`
  & .ant-table-cell:not(th) {
    padding: 0px 8px !important;
  }
`;

function DocumentsTable({ debtorId }) {
  const { isAgencyUserType } = useUserType();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isUploadDocumentModalVisible, setUploadDocumentModalVisible] = useState(false);
  const { data: constants, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();
  const { data: agencyDocuments, isLoading: isAgencyDocumentsLoading } = useGetDocumentsQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: creditorPortalDocuments, isLoading: isCreditorPortalDocumentsLoading } =
    useGetCreditorPortalDebtorDocumentsQuery({ debtorId }, { skip: isAgencyUserType });
  const [documents, isLoading] = isAgencyUserType
    ? [agencyDocuments, isAgencyDocumentsLoading]
    : [creditorPortalDocuments, isCreditorPortalDocumentsLoading];
  const [deleteCreditorPortalDocument] = useDeleteCreditorPortalDebtorDocumentMutation();
  const [deleteAgencyDocument] = useDeleteDebtorDocumentMutation();
  const deleteDocument = isAgencyUserType ? deleteAgencyDocument : deleteCreditorPortalDocument;
  const {
    data: {
      [PERMISSIONS.DEBTOR_DOCUMENT__GET]: isDebtorDocumentGetAuthorized,
      [PERMISSIONS.DEBTOR_DOCUMENT__CREATE]: isDebtorDocumentCreateAuthorized,
      [PERMISSIONS.DEBTOR_DOCUMENT__DELETE]: isDebtorDocumentDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (payload) => {
    const result = await deleteDocument(payload);
    if ("data" in result) {
      message.success("Document removed successfully!");
    }
  };

  const onUploadDocumentOk = () => {
    setUploadDocumentModalVisible(false);
    setSelectedDocument(null);
  };

  const onUploadDocumentCancel = () => {
    setUploadDocumentModalVisible(false);
    setSelectedDocument(null);
  };

  const columns = [
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => new Date(record.createdAt).toLocaleDateString(),
      sorter: {
        compare: (a, b) => {
          const dateA = a.createdAt;
          const dateB = b.createdAt;
          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        const tokens = text.split("_").map((val) => {
          return capitalizeFirstLetter(val);
        });
        const prettifiedText = tokens.join(" ");
        return <div>{prettifiedText}</div>;
      },
      sorter: (a, b) => {
        return a.type.toLowerCase() > b.type.toLowerCase();
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
      render: (text, record) => {
        return record.file ? (
          <a target="_blank" href={new URL(record.file, config.apiBaseUrl).href} rel="noreferrer">
            {text}
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: "External URL",
      dataIndex: "externalUrl",
      key: "externalUrl",
      width: 200,
      render: (externalUrl) => {
        return externalUrl ? (
          <a target="_blank" href={externalUrl} rel="noreferrer">
            {externalUrl}
          </a>
        ) : null;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Data Source",
      dataIndex: "dataSource",
      render: (text, record) =>
        constants?.editableDataSourceTypes?.find((x) => x.value === record.dataSource)?.display ??
        "-",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      hidden: !isDebtorDocumentDeleteAuthorized,
      render: (text, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedDocument(record);
                setUploadDocumentModalVisible(true);
              }}
            />
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this document?"
              onConfirm={() =>
                handleDelete({
                  documentId: record.id,
                  debtorId: parseInt(debtorId, 10),
                  type: record.type,
                })
              }
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ].filter((column) => !column.hidden);

  return isDebtorDocumentGetAuthorized ? (
    <>
      {isDebtorDocumentCreateAuthorized && (
        <StyledButton
          type="link"
          onClick={() => setUploadDocumentModalVisible(true)}
          icon={<PlusOutlined />}
        >
          Add Document
        </StyledButton>
      )}
      <StyledTable
        bordered
        loading={isLoading || isConstantsLoading}
        style={{ cursor: "pointer" }}
        // @ts-ignore
        columns={columns}
        dataSource={documents}
        scroll={{ x: "max-content" }}
      />
      {isUploadDocumentModalVisible && (
        <UploadDocumentModal
          debtorId={debtorId}
          title={selectedDocument ? "Edit Document" : "Add Document"}
          onOk={onUploadDocumentOk}
          onCancel={onUploadDocumentCancel}
          selectedDocument={selectedDocument}
        />
      )}
    </>
  ) : null;
}

export default DocumentsTable;
