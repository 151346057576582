import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Tooltip, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { TableFormList } from "components/formList";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import { useFetchAgencyWorkflowStates } from "features/home/agencyPortal/homeAPI";
import {
  useAddCommunicationSequenceStepMutation,
  useCreateCommunicationSequenceMutation,
  useDeleteCommunicationSequenceStepMutation,
  useFetchCommunicationSequenceStepsQuery,
  useUpdateCommunicationSequenceMutation,
  useUpdateCommunicationSequenceStepMutation,
} from "features/sequencesTable/communicationSequenceAPI";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1890ff;
  margin-left: 4px;
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function AddSequenceModal({
  title,
  open,
  onOk,
  onCancel,
  selectedSequence,
  setSelectedSequence,
}) {
  const [form] = Form.useForm();
  const isNew = !selectedSequence;

  const [createCommunicationSequence, { isLoading: isCreateSequenceLoading }] =
    useCreateCommunicationSequenceMutation();
  const [updateCommunicationSequence, { isLoading: isUpdateSequenceLoading }] =
    useUpdateCommunicationSequenceMutation();
  const [addCommunicationSequenceStep, { isLoading: isCreateSequenceStepLoading }] =
    useAddCommunicationSequenceStepMutation();
  const [updateCommunicationSequenceStep, { isLoading: isUpdateSequenceStepLoading }] =
    useUpdateCommunicationSequenceStepMutation();
  const [deleteCommunicationSequenceStep] = useDeleteCommunicationSequenceStepMutation();
  const { data: sequenceSteps, isFetching: isFetchingSteps } =
    useFetchCommunicationSequenceStepsQuery(
      {
        sequenceId: selectedSequence?.id,
        orderBy: "id",
      },
      { skip: isNew, refetchOnMountOrArgChange: true },
    );
  const { data: defaultWorkflowStates } = useFetchAgencyWorkflowStates();
  const { data: communicationMethods } = useFetchCommunicationMethodsQuery();

  const communicationMethodFiltered = communicationMethods?.filter((method) => !method.isArchived);

  const isActionInProgress =
    isCreateSequenceLoading ||
    isUpdateSequenceLoading ||
    isCreateSequenceStepLoading ||
    isUpdateSequenceStepLoading;

  useEffect(() => {
    if (selectedSequence) {
      form.setFieldsValue(selectedSequence);
    } else {
      form.resetFields();
    }
  }, [form, selectedSequence]);

  const save = async () => {
    const fields = await form.validateFields();

    const action = isNew
      ? createCommunicationSequence({ ...fields })
      : updateCommunicationSequence({ ...fields, sequenceId: selectedSequence.id });
    const result = await action;

    if ("error" in result) {
      form.setFields(formatError(result.error));
    }

    if (isNew && "data" in result) {
      setSelectedSequence(result.data);
    }
    return result;
  };

  const onSubmit = async () => {
    const result = await save();
    if ("data" in result) {
      const successMessage = isNew
        ? "Communication sequence added successfully!"
        : "Communication sequence updated successfully!";
      message.success(successMessage);
    }

    if ("data" in result) {
      form.resetFields();
      onOk();
    }
  };

  // We run this hook when Add Fee Plan Rule is pressed
  const preAddCommunicationSequenceRowHook = async () => {
    if (isNew) {
      const result = await save();
      if ("error" in result) {
        return false;
      }
    }
  };

  const sortedSteps = useMemo(() => {
    if (!selectedSequence || !sequenceSteps || isFetchingSteps) {
      return [];
    }

    // Firstly, we create a map of all the steps by their ID
    const mapById = sequenceSteps.reduce(
      (acc, step) => ({
        ...acc,
        [step.id]: step,
      }),
      {},
    );

    // Then we find the first step in the sequence
    let cursor = sequenceSteps.find(
      (step) => step.previousStepId === null || step.previousStepId === undefined,
    )?.id;

    // Lastly, sort linked list into array
    const resultArray = [];

    while (cursor !== null && cursor !== undefined) {
      resultArray.push(mapById[cursor]);
      cursor = mapById[cursor].nextStepId;
    }

    return resultArray;
  }, [selectedSequence, sequenceSteps, isFetchingSteps]);

  const columns = [
    {
      editable: false,
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (index) => index,
    },
    {
      dataIndex: "communicationMethodId",
      key: "communicationMethodId",
      render: (index, record) =>
        communicationMethodFiltered?.find((method) => method.id === record.communicationMethodId)
          ?.name,
      editable: true,
      inputType: "select",
      title: "Communication Method",
      options: communicationMethodFiltered?.map((method) => ({
        label: method.name,
        value: method.id,
      })),
      inputProps: {
        showSearch: true,
        filterOption,
      },
      rules: [{ required: true, message: "Select Channel Type" }],
    },
    {
      dataIndex: "name",
      editable: true,
      inputType: "text",
      title: "Name",
      rules: [{ required: true, message: "Enter Name" }],
    },
    {
      title: (
        <>
          Wait time (# of Days)
          <Tooltip title="This step will start the specified number of days after an account is added">
            <StyledQuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "waitDays",
      key: "waitDays",
      editable: true,
      inputType: "number",
    },
  ];

  return (
    <Modal
      width={1000}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{ loading: isActionInProgress }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item
          name="name"
          label="Sequence Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="stopWorkflowStateIds" label="Stop Conditions">
          <Select
            mode="multiple"
            filterOption={filterOption}
            popupMatchSelectWidth={false}
            placeholder="Select one or more..."
            options={defaultWorkflowStates?.map((state) => ({
              value: state.id,
              label: [state.code, state.name].join(" - "),
            }))}
          />
        </Form.Item>
      </StyledForm>
      <h4>Steps</h4>
      Define the steps for this sequence.
      <br />
      <br />
      <TableFormList
        addText="Add Step"
        columns={columns}
        data={isNew ? [] : sortedSteps}
        onDelete={(payload) =>
          deleteCommunicationSequenceStep({
            sequenceId: selectedSequence.id,
            ...payload,
          })
        }
        onSave={async (payload) => {
          const previousStepId = sortedSteps?.[sortedSteps.length - 1]?.id;

          return payload?.id
            ? updateCommunicationSequenceStep({
                sequenceId: selectedSequence.id,
                ...payload,
              })
            : addCommunicationSequenceStep({
                sequenceId: selectedSequence.id,
                previousStepId,
                ...payload,
              });
        }}
        preAddRowHook={preAddCommunicationSequenceRowHook}
      />
    </Modal>
  );
}
