import { ContainerOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { toTitleCase } from "common/utils";
import {
  useArchiveCommunicationMethodMutation,
  useFetchCommunicationMethodsQuery,
} from "features/communicationMethodsTable/communicationMethodsAPI";
import CommunicationMethodModal from "features/communicationMethodsTable/components/communicationMethodModal";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function SequencesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCommunicationMethod, setSelectedCommunicationMethod] = useState(null);
  const [archiveCommunicationMethod] = useArchiveCommunicationMethodMutation();
  const { data: communicationMethods, isLoading } = useFetchCommunicationMethodsQuery();

  const handleArchiveCommunicationMethod = async ({ communicationMethodId }) => {
    const result = await archiveCommunicationMethod({ communicationMethodId });

    if ("data" in result) {
      message.success("Communication method archived successfully!");
    }
  };

  const mediumTitle = (record) => {
    const title = toTitleCase({ str: record.medium, delimiter: "_" }) ?? "-";
    return record.useMultipleAccountsPerTask ? `${title} (Multiple Accounts)` : title;
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Letter Code",
      dataIndex: "letterCode",
      key: "letterCode",
      render: (index, record) => record.letterCode ?? "-",
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (index, record) => mediumTitle(record),
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: (index, record) => toTitleCase({ str: record.provider, delimiter: "_" }) ?? "-",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) =>
        !record.isArchived && (
          <Space size="middle">
            <EditOutlined
              key="edit"
              disabled={record.isArchived}
              onClick={() => {
                setSelectedCommunicationMethod(record);
                setModalVisible(true);
              }}
            />
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to archive this communication method?"
              description={
                <p>
                  Archiving the communication method will archive all the future scheduled
                  communications (e.g text, email) associated with this communication method. <br />
                  You must delete communication steps using this communication method before taking
                  this action.
                </p>
              }
              onConfirm={() =>
                handleArchiveCommunicationMethod({ communicationMethodId: record.id })
              }
            >
              <ContainerOutlined key="archive" />
            </Popconfirm>
          </Space>
        ),
    },
  ];

  const onNewRuleOk = () => {
    setModalVisible(false);
    setSelectedCommunicationMethod(null);
  };

  return (
    <>
      <Row align="middle">
        <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
          Add Communication Method
        </Button>
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={communicationMethods?.filter((method) => !method.isArchived)}
      />
      {isModalVisible && (
        <CommunicationMethodModal
          title={`${selectedCommunicationMethod ? "Edit" : "Add"} Communication Method`}
          open={isModalVisible}
          onOk={onNewRuleOk}
          selectedCommunicationMethod={selectedCommunicationMethod}
          onCancel={() => {
            setModalVisible(false);
            setSelectedCommunicationMethod(null);
          }}
        />
      )}
    </>
  );
}
