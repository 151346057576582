import { Col, Form, Row, Select } from "antd";
import styled from "styled-components";
import { useFetchDebtorTagsQuery } from "features/tags/tagsAPI";

const StyledSelect = styled(Select)`
  max-width: 400px !important;
  margin-right: 4px;
`;

export default function DebtorTagSubForm() {
  const form = Form.useFormInstance();
  const { data: debtorTags = [], isLoading: isDebtorTagsLoading } = useFetchDebtorTagsQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const validateUpdateDebtorTags = (_, value) => {
    const addTagIds = form.getFieldValue(["operations", "updateDebtorTags", "addTagIds"]);
    const removeTagIds = form.getFieldValue(["operations", "updateDebtorTags", "removeTagIds"]);

    if ((!addTagIds || addTagIds.length === 0) && (!removeTagIds || removeTagIds.length === 0)) {
      return Promise.reject(
        new Error("Either 'Tags To Add' or 'Tags To Remove' must be provided."),
      );
    }

    if (addTagIds && removeTagIds) {
      const duplicateTags = addTagIds.filter((tagId) => removeTagIds.includes(tagId));
      if (duplicateTags.length > 0) {
        return Promise.reject(
          new Error("The same tag cannot be in both 'Tags To Add' and 'Tags To Remove'."),
        );
      }
    }

    return Promise.resolve();
  };

  return (
    <>
      <Row align="middle" gutter={12}>
        <Col span={12}>
          <Form.Item
            name={["operations", "updateDebtorTags", "addTagIds"]}
            label="Tags To Add"
            rules={[{ validator: validateUpdateDebtorTags }]}
          >
            <StyledSelect
              allowClear
              showSearch
              mode="multiple"
              filterOption={filterOption}
              popupMatchSelectWidth={false}
              options={debtorTags.map((tag) => ({
                label: tag.name,
                value: tag.id,
              }))}
              placeholder="Select tags..."
              loading={isDebtorTagsLoading}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" gutter={12}>
        <Col span={12}>
          <Form.Item
            name={["operations", "updateDebtorTags", "removeTagIds"]}
            label="Tags To Remove"
            rules={[{ validator: validateUpdateDebtorTags }]}
          >
            <StyledSelect
              allowClear
              showSearch
              mode="multiple"
              filterOption={filterOption}
              popupMatchSelectWidth={false}
              options={debtorTags.map((tag) => ({
                label: tag.name,
                value: tag.id,
              }))}
              placeholder="Select tags..."
              loading={isDebtorTagsLoading}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
