import api, { transformMultipart, transformRequest, transformResponse } from "services/api";

export const organizationSettingsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "Creditors",
      "CreditorContacts",
      "CreditorAddresses",
      "CreditorDocuments",
      "CreditorTags",
      "CreditorGroup",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditors: build.query({
        query: () => "/api/v1/core/creditors/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "Creditors", id })),
                { type: "Creditors", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Creditors', id: 'LIST' }` is invalidated
              [{ type: "Creditors", id: "LIST" }],
      }),
      fetchCreditorContacts: build.query({
        query: (payload) => `/api/v1/core/creditors/${payload.creditorId}/contacts/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorContacts", id })),
                { type: "CreditorContacts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorContacts', id: 'LIST' }` is invalidated
              [{ type: "CreditorContacts", id: "LIST" }],
      }),
      fetchCreditorAddresses: build.query({
        query: (payload) => `/api/v1/core/creditors/${payload.creditorId}/addresses/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorAddresses", id })),
                { type: "CreditorAddresses", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorAddresses', id: 'LIST' }` is invalidated
              [{ type: "CreditorAddresses", id: "LIST" }],
      }),
      fetchCreditorTags: build.query({
        query: () => `/api/v1/core/creditor-tags/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorTags", id })),
                { type: "CreditorTags", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorTags', id: 'LIST' }` is invalidated
              [{ type: "CreditorTags", id: "LIST" }],
      }),
      createCreditor: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/creditors/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [
          { type: "Creditors", id: "LIST" },
          { type: "CreditorTags", id: "LIST" },
          { type: "CreditorGroup", id: "LIST" },
        ],
      }),
      updateCreditor: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditors/${payload.id}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "Creditors", id: arg.id },
          { type: "CreditorTags", id: "LIST" },
          { type: "CreditorGroup", id: "LIST" },
        ],
      }),
      deleteCreditor: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditors/${payload.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "Creditors", id: "LIST" }],
      }),
      createCreditorAddress: build.mutation({
        query: ({ creditorId, address }) => ({
          url: `/api/v1/core/creditors/${creditorId}/addresses/create/`,
          method: "POST",
          body: transformRequest(address),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorAddresses", id: "LIST" }],
      }),
      deleteCreditorAddress: build.mutation({
        query: ({ creditorId, address }) => ({
          url: `/api/v1/core/creditors/${creditorId}/addresses/${address.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "CreditorAddresses", id: "LIST" }],
      }),
      updateCreditorAddress: build.mutation({
        query: ({ creditorId, address }) => ({
          url: `/api/v1/core/creditors/${creditorId}/addresses/${address.id}/update/`,
          method: "POST",
          body: transformRequest(address),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "CreditorAddresses", id: arg.address.id },
        ],
      }),
      createCreditorContact: build.mutation({
        query: ({ creditorId, contact }) => ({
          url: `/api/v1/core/creditors/${creditorId}/contacts/create/`,
          method: "POST",
          body: transformRequest(contact),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "CreditorContacts", id: "LIST" }],
      }),
      deleteCreditorContact: build.mutation({
        query: ({ creditorId, contact }) => ({
          url: `/api/v1/core/creditors/${creditorId}/contacts/${contact.id}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "CreditorContacts", id: "LIST" }],
      }),
      updateCreditorContact: build.mutation({
        query: ({ creditorId, contact }) => ({
          url: `/api/v1/core/creditors/${creditorId}/contacts/${contact.id}/update/`,
          method: "POST",
          body: transformRequest(contact),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [{ type: "CreditorContacts", id: arg.contact.id }],
      }),
      fetchCreditorCosts: build.query({
        query: (payload) => ({
          url: `/api/v1/core/creditor-costs/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorCosts", id })),
                { type: "CreditorCosts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorTags', id: 'LIST' }` is invalidated
              [{ type: "CreditorCosts", id: "LIST" }],
      }),
      deleteCreditorCost: build.mutation({
        query: ({ creditorCostId }) => ({
          url: `/api/v1/core/creditor-costs/${creditorCostId}/delete/`,
          method: "POST",
        }),
        invalidatesTags: [{ type: "CreditorCosts", id: "LIST" }],
      }),
      fetchCreditorDocuments: build.query({
        query: (payload) => `/api/v1/core/creditors/${payload.creditorId}/documents/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorDocuments", id })),
                { type: "CreditorDocuments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorContacts', id: 'LIST' }` is invalidated
              [{ type: "CreditorDocuments", id: "LIST" }],
      }),
      deleteCreditorDocument: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditors/${payload.creditorId}/documents/${payload.documentId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "CreditorDocuments", id: "LIST" }],
      }),
      createCreditorDocument: build.mutation({
        query: ({ creditorId, file, ...values }) => ({
          url: `/api/v1/core/creditors/${creditorId}/documents/create/`,
          method: "POST",
          body: transformMultipart(values, file),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CreditorDocuments-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CreditorDocuments", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useDeleteCreditorDocumentMutation,
  useDeleteCreditorCostMutation,
  useFetchCreditorsQuery,
  useFetchCreditorContactsQuery,
  useFetchCreditorAddressesQuery,
  useFetchCreditorTagsQuery,
  useFetchCreditorCostsQuery,
  useFetchCreditorDocumentsQuery,
  useCreateCreditorMutation,
  useUpdateCreditorMutation,
  useCreateCreditorAddressMutation,
  useUpdateCreditorAddressMutation,
  useCreateCreditorContactMutation,
  useUpdateCreditorContactMutation,
  useCreateCreditorDocumentMutation,
  useDeleteCreditorAddressMutation,
  useDeleteCreditorContactMutation,
  useDeleteCreditorMutation,
} = organizationSettingsApi;
