import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Modal, message } from "antd";
import { PAYMENT_INTENT_STATUS } from "common/constants";
import {
  useBulkDebtorDeletePaymentIntentsMutation,
  useExecutePaymentIntentMutation,
  useRefundPaymentIntentMutation,
  useUnsettlePaymentIntentMutation,
  useVoidPaymentIntentMutation,
} from "features/payments/paymentsAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import styled from "styled-components";

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 18px;
`;

const { confirm } = Modal;

export default function PaymentIntentActions({ record }) {
  const [deletePaymentIntent] = useBulkDebtorDeletePaymentIntentsMutation();
  const [refundPaymentIntent] = useRefundPaymentIntentMutation();
  const [unsettlePaymentIntent] = useUnsettlePaymentIntentMutation();
  const [executePaymentIntent] = useExecutePaymentIntentMutation();
  const [voidPaymentIntent] = useVoidPaymentIntentMutation();

  const {
    data: {
      [PERMISSIONS.PAYMENT__DELETE]: isPaymentDeleteAuthorized,
      [PERMISSIONS.PAYMENT__REVERSE]: isPaymentReversalAuthorized,
      [PERMISSIONS.PAYMENT__VOID]: isPaymentVoidAuthorized,
      [PERMISSIONS.PAYMENT__EXECUTE]: isPaymentExecuteAuthorized,
    },
  } = useAuthorizations();

  const items = [
    {
      key: "reverse",
      label: "Reverse Payment",
      actionHandler: refundPaymentIntent,
      isVisible: (item) =>
        isPaymentReversalAuthorized && item.status === PAYMENT_INTENT_STATUS.EXECUTED,
    },
    {
      key: "markAsReversed",
      label: "Mark as Reversed (Aktos Only)",
      actionHandler: (payload) => {
        return refundPaymentIntent({ ...payload, aktosOnly: true });
      },
      isVisible: (item) =>
        isPaymentReversalAuthorized &&
        item.status === PAYMENT_INTENT_STATUS.EXECUTED &&
        !item.isLoggingOnly,
    },
    {
      key: "delete",
      label: "Delete Payment",
      actionHandler: deletePaymentIntent,
      isVisible: (item) =>
        // Always available for logging only payments.
        // For processed payments, refund/void attempt is required before the delete option appears.
        isPaymentDeleteAuthorized &&
        (item.isLoggingOnly ||
          [
            PAYMENT_INTENT_STATUS.REFUNDED,
            PAYMENT_INTENT_STATUS.VOIDED,
            PAYMENT_INTENT_STATUS.EXECUTE_ERROR,
            PAYMENT_INTENT_STATUS.REFUND_ERROR,
            PAYMENT_INTENT_STATUS.VOID_ERROR,
          ].includes(item.status)),
    },
    {
      key: "unsettle",
      label: "Undo Settlement",
      actionHandler: unsettlePaymentIntent,
      isVisible: (item) => item.status === PAYMENT_INTENT_STATUS.EXECUTED && item.isSettlement,
    },
    {
      key: "execute",
      label: "Execute Payment",
      actionHandler: executePaymentIntent,
      isVisible: (item) =>
        isPaymentExecuteAuthorized &&
        item.isLoggingOnly &&
        item.status === PAYMENT_INTENT_STATUS.NEW,
    },
    {
      key: "void",
      label: "Void Payment",
      actionHandler: voidPaymentIntent,
      isVisible: (item) =>
        isPaymentVoidAuthorized && item.status === PAYMENT_INTENT_STATUS.EXECUTED,
    },
  ];

  const handleOk = (item, key, row) => {
    confirm({
      title:
        key === "markAsReversed"
          ? "Are you sure you want to mark this payment as reversed (Aktos only)? Please ensure the reversal has already been processed by the external payment processor."
          : `Are you sure you want to ${key} this payment?`,
      icon: <ExclamationCircleOutlined />,
      content: key === "delete" && (
        <div>
          <p>
            Deleting this payment will not affect the last "interest calculation date" for the
            affected accounts.
          </p>
        </div>
      ),
      async onOk() {
        const result = await item.actionHandler({
          debtorId: row.debtorId,
          paymentIntentId: row.id,
          paymentIntentIds: [row.id],
        });

        if ("error" in result) {
          switch (key) {
            case "markAsReversed":
              message.error("Failed to mark payment as reversed");
              break;
            default:
              message.error(`Failed to ${key} payment`);
          }
        }
        if ("data" in result) {
          switch (key) {
            case "delete":
              message.success("Payment successfully deleted");
              break;
            case "reverse":
              message.success("Payment successfully reversed");
              break;
            case "markAsReversed":
              message.success("Payment successfully marked as reversed");
              break;
            case "unsettle":
              message.success("Payment successfully unsettled");
              break;
            case "execute":
              message.success("Payment successfully executed");
              break;
            case "void":
              message.success("Payment successfully voided");
              break;
            default:
              message.success(`Payment successfully ${key}ed`);
          }
        }
      },
    });
  };

  const handleMenuClick = ({ item, key, keyPath, domEvent }, row) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items array
    handleOk(item.props, key, row);
  };

  const itemsToDisplay = items.filter((item) => item.isVisible(record));
  if (itemsToDisplay.length === 0) {
    return <div>No actions available</div>;
  }
  return (
    <Space size="small">
      <Dropdown
        menu={{
          // @ts-ignore
          items: itemsToDisplay,
          onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp, record),
        }}
        trigger={["click"]}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          type="text"
        >
          <StyledEllipsisOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
}
