import * as Sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// NOTE: This must be set as relative imports, else the styles do not render properly in the dashboard.
import config from "app/envConfig";
import { setupStore } from "common/redux/store";
import {
  accountsTableSlice,
  loadAccountsTableSlice,
} from "features/accountsTable/accountsTableSlice";
import { UserTypeErrorBoundary, UserTypeRoute } from "features/auth";
import { authSlice, loadAuthSlice } from "features/auth/authSlice";
import { getProtectedRoute } from "features/auth/components/getAuthComponents";
import { AccountAssignmentPage } from "pages/agencyPortal/accountAssignment";
import { AccountsPage } from "pages/agencyPortal/accounts";
import { BulkActionsPage } from "pages/agencyPortal/bulkActions";
import { CommunicationLimitsPage } from "pages/agencyPortal/communicationLimits";
import { CourtsOfLawPage } from "pages/agencyPortal/courtsOfLaw";
import { CreditorProfilePage } from "pages/agencyPortal/creditorProfile";
import { DataImportPage } from "pages/agencyPortal/dataImport";
import { DebtorsPage } from "pages/agencyPortal/debtors";
import { DocumentCenterPage } from "pages/agencyPortal/documentCenter";
import { EmployersPage } from "pages/agencyPortal/employers";
import { NotFoundPage as AgencyNotFoundPage } from "pages/agencyPortal/errorPages";
import AgencyErrorBoundaryPage from "pages/agencyPortal/errorPages/errorBoundaryPage";
import { EventsPage } from "pages/agencyPortal/events";
import { ForwardingEntitiesPage } from "pages/agencyPortal/forwardingEntities";
import { InvoicesPage } from "pages/agencyPortal/invoices";
import { LawFirmsPage } from "pages/agencyPortal/lawFirms";
import { PriorityAccountPage } from "pages/agencyPortal/priorityAccount";
import { ReportsPage } from "pages/agencyPortal/reports";
import { SequencesPage } from "pages/agencyPortal/sequences";
import { SettingsPage } from "pages/agencyPortal/settings";
import { WorklistPage } from "pages/agencyPortal/worklists";
import { ChangePassword } from "pages/agencyPortal/changePassword";
import { AccountsPage as AccountsCreditorPage } from "pages/creditorPortal/account";
import { DataImportPage as DataImportCreditorPage } from "pages/creditorPortal/dataImport";
import { DebtorsPage as DebtorsCreditorPage } from "pages/creditorPortal/debtors";
import { NotFoundPage as CreditorNotFoundPage } from "pages/creditorPortal/errorPages";
import CreditorErrorBoundaryPage from "pages/creditorPortal/errorPages/errorBoundaryPage";
import { MetabaseReportsPage } from "pages/creditorPortal/metabaseReports";
import DisputeManagementPage from "pages/disputeManagement/disputeManagementPage";
import { GlobalErrorPage } from "pages/errorPages";
import { Login } from "pages/login";
import { Logout } from "pages/logout";
import { SignUp } from "pages/signup";
import { UserManagementPage } from "pages/userManagement";
import { WorkflowsPage } from "pages/workflows";
import { ReduxStoreProvider, SiderProvider } from "providers";
import WhiteLabelProvider from "providers/whiteLabelProvider";
import { useDocumentTitle } from "usehooks-ts";
import "./App.css";
import PaymentsPage from "pages/agencyPortal/payments/paymentsPage";
import CommunicationTaskPage from "pages/communications/communicationTaskPage";

const CREDITOR_ROUTES = [
  { path: "/change-password", Component: ChangePassword },
  { path: "/logout", Component: Logout },
  { path: "/", Component: AccountsCreditorPage },
  { path: "/accounts", Component: AccountsCreditorPage },
  { path: "/worklist", Component: AccountsCreditorPage },
  { path: "/debtors/*", Component: DebtorsCreditorPage },
  { path: "/data-import", Component: DataImportCreditorPage },
  { path: "/reports", Component: MetabaseReportsPage },
  { path: "*", Component: CreditorNotFoundPage },
];

const AGENCY_ROUTES = [
  { path: "/change-password", Component: ChangePassword },
  { path: "/logout", Component: Logout },
  { path: "/", Component: AccountsPage },
  { path: "/worklist", Component: AccountsPage },
  { path: "/accounts", Component: AccountsPage },
  { path: "/debtors/*", Component: DebtorsPage },
  { path: "/assign", Component: AccountAssignmentPage },
  { path: "/events", Component: EventsPage },
  { path: "/reports", Component: ReportsPage },
  { path: "/priority", Component: PriorityAccountPage },
  { path: "/invoices/*", Component: InvoicesPage },
  { path: "/workflows/*", Component: WorkflowsPage },
  { path: "/settings/*", Component: SettingsPage },
  { path: "/data-import", Component: DataImportPage },
  { path: "/document-center", Component: DocumentCenterPage },
  { path: "/sequences", Component: SequencesPage },
  { path: "/bulk-actions", Component: BulkActionsPage },
  { path: "/communication-limits", Component: CommunicationLimitsPage },
  { path: "/creditors/*", Component: CreditorProfilePage },
  { path: "/user-management/", Component: UserManagementPage },
  { path: "/worklists/", Component: WorklistPage },
  { path: "/disputes/", Component: DisputeManagementPage },
  { path: "/courts-of-law/", Component: CourtsOfLawPage },
  { path: "/law-firms/", Component: LawFirmsPage },
  { path: "/employers/", Component: EmployersPage },
  { path: "/forwarding-entities/", Component: ForwardingEntitiesPage },
  { path: "/payments/*", Component: PaymentsPage },
  { path: "/communication-tasks/*", Component: CommunicationTaskPage },
  { path: "*", Component: AgencyNotFoundPage },
];

const store = setupStore({
  [authSlice.name]: loadAuthSlice(),
  [accountsTableSlice.name]: loadAccountsTableSlice(),
});

function App() {
  const isDevMode = config.aktosEnv !== "prod";
  const aktosTitle = isDevMode ? `[${config.aktosEnv}] Aktos` : "Aktos";
  useDocumentTitle(aktosTitle);

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const ProtectedRoute = getProtectedRoute();

  const router = sentryCreateBrowserRouter([
    {
      errorElement: <GlobalErrorPage />,
      children: [
        { path: "/login", Component: Login },
        { path: "/signup", Component: SignUp },
        {
          path: "*",
          Component: ProtectedRoute,
          errorElement: <GlobalErrorPage />,
          children: [
            {
              path: "*",
              element: (
                <UserTypeRoute
                  routes={{
                    agency: AGENCY_ROUTES,
                    creditor: CREDITOR_ROUTES,
                  }}
                />
              ),
              errorElement: (
                <UserTypeErrorBoundary
                  route={{
                    agency: <AgencyErrorBoundaryPage />,
                    creditor: <CreditorErrorBoundaryPage />,
                    fallback: <GlobalErrorPage />,
                  }}
                />
              ),
            },
          ],
        },
      ],
    },
  ]);

  return (
    <ReduxStoreProvider store={store}>
      <SiderProvider>
        <WhiteLabelProvider>
          <RouterProvider router={router} />
        </WhiteLabelProvider>
      </SiderProvider>
    </ReduxStoreProvider>
  );
}

export default Sentry.withProfiler(App);
