import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Modal, message } from "antd";
import { COMMUNICATION_TASK_STATUS } from "common/constants";
import { useBulkCancelCommunicationTasksMutation } from "features/communicationTasks/communicationTasksAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import styled from "styled-components";

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 18px;
`;

const { confirm } = Modal;

export default function CommunicationTasksActions({ record }) {
  const [cancelCommunicationTasks] = useBulkCancelCommunicationTasksMutation();

  const {
    data: { [PERMISSIONS.COMMUNICATION_TASK__CANCEL]: isCommunicationTaskCancelAuthorized },
  } = useAuthorizations();

  const items = [
    {
      key: "cancel",
      label: "Cancel Communication Task",
      actionHandler: cancelCommunicationTasks,
      isVisible: (item) =>
        isCommunicationTaskCancelAuthorized && item.status === COMMUNICATION_TASK_STATUS.SCHEDULED,
    },
  ];

  const handleOk = (item, key, row) => {
    confirm({
      title: `Are you sure you want to ${key} this communication task?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await item.actionHandler({
          communicationTaskIds: [row.id],
        });

        if ("error" in result) {
          message.error(`Failed to ${key} communication task`);
        }
        if ("data" in result) {
          switch (key) {
            case "cancel":
              message.success("Communication task successfully canceled");
              break;
            default:
              message.success(`Communication task successfully ${key}ed`);
          }
        }
      },
    });
  };

  const handleMenuClick = ({ item, key, keyPath, domEvent }, row) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items array
    handleOk(item.props, key, row);
  };

  const itemsToDisplay = items.filter((item) => item.isVisible(record));
  if (itemsToDisplay.length === 0) {
    return <div>No actions available</div>;
  }
  return (
    <Space size="small">
      <Dropdown
        menu={{
          // @ts-ignore
          items: itemsToDisplay,
          onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp, record),
        }}
        trigger={["click"]}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          type="text"
        >
          <StyledEllipsisOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
}
