import { Checkbox, Form, InputNumber, Row, Select } from "antd";
import { AktDateRangePicker } from "components/aktDatePicker";
import styled from "styled-components";

const StyledFilterHeader = styled.h4`
  font-weight: 600;
  color: #1677ff;
`;

const StyledSubHeader = styled.h5`
  margin-top: 0;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

function TableFilterItem(props) {
  /**
   * TableFilterItem component renders different types of filter items based on the provided type.
   *
   * Expected item structure:
   * {
   *    title: "Filter title",
   *    name: "filterName",
   *    type: "date" | "include/exclude" | "dropdown" | "checkbox-group" | "checkbox-option" | "min/max",
   *    onChange: function to handle the change of the filter
   * }
   *
   * Specific for type "include/exclude":
   * {
   *    include : {
   *        mode: "single" | "multiple",
   *        onChange: function to handle the change of the filter,
   *        options: {[{ value: 'sample', label: <span>sample</span> }]},
   *        placeholder: "Select a value",
   *    },
   *    exclude : {
   *        mode: "single" | "multiple",
   *        onChange: function to handle the change of the filter,
   *        options: {[{ value: 'sample', label: <span>sample</span> }]},
   *        placeholder: "Select a value",
   *    }
   * }
   *
   * Specific for type "dropdown":
   * {
   *    mode: "single" | "multiple",
   *    onChange: function to handle the change of the filter,
   *    options: {[{ value: 'sample', label: <span>sample</span> }]},
   *    placeholder: "Select a value",
   *    initialValue: "initial value"
   * }
   *
   * Specific for type "checkbox-group" and "checkbox-option":
   * {
   *   options: [{ label: 'sample', value: 'sample' }],
   *   onChange: function to handle the change of the filter,
   *   value: item value
   * }
   *
   * Specific for type "min/max":
   * {
   *  min: {prefix: '$', step: '0.01', min: '0', onChange: "function to handle the change of the filter"},
   *  max: {prefix: '$', step: '0.01', min: '0', onChange: "function to handle the change of the filter"},
   * }
   */
  const {
    item: {
      title,
      name,
      type,
      onChange,
      initialValue,
      include,
      exclude,
      dropdown,
      options,
      min,
      max,
      value,
    },
  } = props;

  function renderComponentBasedOnType() {
    switch (type) {
      case "date":
        return (
          <Form.Item name={name}>
            <AktDateRangePicker
              picker="date"
              onChange={onChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
        );
      case "include/exclude":
        return (
          <>
            <StyledSubHeader>Include</StyledSubHeader>
            <Form.Item name={`${name}Include`}>
              <Select
                mode={include.mode}
                popupMatchSelectWidth={false}
                placeholder={include.placeholder}
                allowClear
                onChange={include.onChange}
                options={include.options}
              />
            </Form.Item>
            <StyledSubHeader>Exclude</StyledSubHeader>
            <Form.Item name={`${name}Exclude`}>
              <Select
                mode={exclude.mode}
                popupMatchSelectWidth={false}
                placeholder={exclude.placeholder}
                allowClear
                onChange={exclude.onChange}
                options={exclude.options}
              />
            </Form.Item>
          </>
        );
      case "dropdown":
        return (
          <Form.Item name={name} initialValue={initialValue}>
            <Select
              mode={dropdown.mode}
              popupMatchSelectWidth={false}
              placeholder={dropdown.placeholder}
              allowClear
              onChange={dropdown.onChange}
              options={dropdown.options}
            />
          </Form.Item>
        );
      case "checkbox-group":
        return <Checkbox.Group options={options} onChange={onChange} value={value} />;
      case "checkbox-option":
        return options.map((option) => {
          return (
            <div className="col-sm-12 px-3 py-2">
              <Checkbox
                key={option.label}
                onChange={onChange}
                checked={option.value === value}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            </div>
          );
        });
      case "min/max":
        return (
          <Row>
            <Form.Item name={`${name}Min`}>
              <StyledInputNumber
                placeholder="Min"
                min={min.min}
                prefix={min.prefix}
                step={min.step}
                onChange={min.onChange}
              />
            </Form.Item>
            <Form.Item name={`${name}Max`}>
              <StyledInputNumber
                placeholder="Max"
                min={max.min}
                prefix={max.prefix}
                step={max.step}
                onChange={max.onChange}
              />
            </Form.Item>
          </Row>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <StyledFilterHeader>{title}</StyledFilterHeader>
      {renderComponentBasedOnType()}
    </>
  );
}
export default TableFilterItem;
