import { Form, Modal, Radio, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useDownloadInvoicesAsyncMutation } from "features/invoices/invoiceAPI";
import { useFetchLetterTemplatesQuery } from "features/letterTemplates/letterTemplatesAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function DownloadPublishedInvoicesModal({ open, onOk, onCancel }) {
  const [form] = useForm();
  const { data: letterTemplates, isLoading: isLoadingLetterTemplates } =
    useFetchLetterTemplatesQuery();
  const [downloadInvoicesAsync, { isLoading: isLoadingInvoices }] =
    useDownloadInvoicesAsyncMutation();

  const onSubmit = async () => {
    const values = await form.validateFields();
    const { invoiceDateRange, letterTemplateId, useCombinedDocument, renderFormat } = values;
    const [invoiceStartDate, invoiceEndDate] = invoiceDateRange;

    const result = await downloadInvoicesAsync({
      letterTemplateId,
      invoiceStartDate,
      invoiceEndDate,
      useCombinedDocument,
      renderFormat,
    });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(
        "Invoice file is being generated. Please check back later in the Generated Documents section.",
      );
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const disabledAfterTodayDate = (current) => {
    const today = moment().endOf("day");
    return current && current > today;
  };

  return (
    <Modal
      maskClosable={false}
      title="Download Published Invoices"
      open={open}
      confirmLoading={isLoadingInvoices}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        layout="vertical"
        form={form}
        disabled={isLoadingInvoices}
        initialValues={{
          invoiceDateRange: [
            moment().startOf("month").format(DATE_FORMAT),
            moment().endOf("day").format(DATE_FORMAT),
          ],
        }}
      >
        <Form.Item
          label="Letter Template"
          name="letterTemplateId"
          rules={[{ required: true, message: "Select letter template" }]}
        >
          <Select
            disabled={isLoadingInvoices || isLoadingLetterTemplates}
            options={letterTemplates
              ?.filter((template) => template.type === "invoice")
              .map((template) => ({
                value: template.id,
                label: template.filename,
              }))}
          />
        </Form.Item>
        <Form.Item
          name="invoiceDateRange"
          label="Invoice Dates"
          rules={[{ required: true, message: "Select invoice date range" }]}
        >
          <AktDateRangePicker picker="date" allowClear />
        </Form.Item>
        <Form.Item name="renderFormat" initialValue="docx" label="File Format">
          <Radio.Group>
            <Radio value="docx">Docx</Radio>
            <Radio value="pdf">PDF</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.renderFormat !== currentValues.renderFormat
          }
        >
          {({ getFieldValue }) => {
            const format = getFieldValue("renderFormat");
            return (
              <Form.Item name="useCombinedDocument" initialValue={false} label="Output As">
                <Radio.Group>
                  {format === "pdf" && <Radio value>Combined Invoices</Radio>}
                  <Radio value={false}>Separate Invoices</Radio>
                </Radio.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default DownloadPublishedInvoicesModal;
