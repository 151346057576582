import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "common/constants";
import { camelToSnakeCase, formatCurrency } from "common/utils";
import { CursorTable } from "components/cursorTable";
import currency from "currency.js";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import AddAdjustmentModal from "features/invoices/components/addAdjustmentModal";
import {
  useDeleteInvoiceItemMutation,
  useDraftInvoiceMutation,
  useFetchInvoicesQuery,
  useFetchInvoiceItemsQuery,
  useFetchInvoiceQuery,
  usePublishInvoiceMutation,
  useUpdateInvoiceMutation,
} from "features/invoices/invoiceAPI";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledValue = styled.span`
  font-size: 12px;
  color: gray;
`;

const StyledRow = styled(Row)`
  margin-bottom: 12px;
`;

const StyledHeader = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; // adds space between items
`;

const StyledCell = styled(Table.Summary.Cell)`
  background: #fafafa;
`;

const StyledDescriptions = styled(Descriptions)`
  margin-top: 12px;
  max-width: fit-content;
`;

const StyledTag = styled(Tag)`
  margin-left: 12px;
  text-transform: uppercase;
`;

const StyledDeliveredButton = styled(Button)`
  background: green;
`;

const DEFAULT_PAGE_SIZE = 10;

function InvoiceDetail() {
  const { invoiceId } = useParams();
  const { data: agency } = useFetchAgencyQuery();
  const [showAddAdjustmentModal, setShowAddAdjustmentModal] = useState(false);
  const [virtualPage, setVirtualPage] = useState(1);
  const [tableSort, setTableSort] = useState("-id");
  const [pagination, setPagination] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    prevToken: null,
    nextToken: null,
  });

  const [updateInvoice, { isLoading: isUpdatingInvoiceLoading }] = useUpdateInvoiceMutation();
  const { data: currentInvoice, isLoading: isInvoicesLoading } = useFetchInvoiceQuery({
    invoiceId,
  });
  const {
    data: { results: childrenInvoices } = {
      results: [],
    },
    isLoading: isChildrenInvoicesLoading,
    isFetching: isChildrenInvoicesFetching,
  } = useFetchInvoicesQuery(
    {
      parentInvoiceId: invoiceId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const {
    data: { results: invoiceItems, meta: { prevToken, nextToken, pageSize } } = {
      results: [],
      meta: { pageSize: DEFAULT_PAGE_SIZE, prevToken: null, nextToken: null },
    },
    isLoading: isInvoiceItemsLoading,
    isFetching: isInvoiceItemsFetching,
  } = useFetchInvoiceItemsQuery(
    {
      orderBy: tableSort,
      invoiceId,
      virtualPage,
      ...pagination,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const childrenInvoicesColumns = [
    {
      title: "Invoice",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a href={`/invoices/${record.id}`} target="_blank" rel="noreferrer">
          {record.id} - {record.name}
        </a>
      ),
    },
    {
      title: "Total Collection",
      dataIndex: "totalCollection",
      key: "totalCollection",
      render: (text, record) => formatCurrency(record.totalCollection),
    },
    {
      title: "Total Credits",
      dataIndex: "totalAmountInCredit",
      key: "totalAmountInCredit",
      render: (text, record) => formatCurrency(record.totalAmountInCredit),
    },
    {
      title: "Total Fees",
      dataIndex: "totalAmountInFees",
      key: "totalAmountInFees",
      render: (text, record) => formatCurrency(record.totalAmountInFees),
    },
    {
      title: "Agency's Share",
      dataIndex: "totalAgencyCommission",
      key: "totalAgencyCommission",
      render: (text, record) => formatCurrency(record.totalAgencyCommission),
    },
    {
      title: "Total Tax",
      dataIndex: "totalTaxOnAgencyCommission",
      key: "totalTaxOnAgencyCommission",
      render: (text, record) => formatCurrency(record.totalTaxOnAgencyCommission),
    },
    {
      title: "Creditor's Share",
      dataIndex: "totalCreditorCommission",
      key: "totalCreditorCommission",
      render: (text, record) => formatCurrency(record.totalCreditorCommission),
    },
    {
      title: "Amount Client Owes Us",
      dataIndex: "summaryTotalAmountDueToAgency",
      key: "summaryTotalAmountDueToAgency",
      render: (text, record) => formatCurrency(record.summaryTotalAmountDueToAgency),
    },
    {
      title: "Amount We Owe Client",
      dataIndex: "summaryTotalAmountDueToCreditor",
      key: "summaryTotalAmountDueToCreditor",
      render: (text, record) => formatCurrency(record.summaryTotalAmountDueToCreditor),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  const { data: creditor } = useFetchCreditorQuery(
    { creditorId: currentInvoice?.creditorId },
    { skip: !currentInvoice?.id },
  );
  const [deleteInvoiceItem] = useDeleteInvoiceItemMutation();
  const [publishInvoice, { isLoading: isPublishingInvoiceLoading }] = usePublishInvoiceMutation();
  const [draftInvoice, { isLoading: isDraftInvoiceLoading }] = useDraftInvoiceMutation();

  const isLoading =
    isInvoicesLoading ||
    isPublishingInvoiceLoading ||
    isUpdatingInvoiceLoading ||
    isDraftInvoiceLoading ||
    isInvoiceItemsLoading ||
    isInvoiceItemsFetching ||
    isChildrenInvoicesLoading ||
    isChildrenInvoicesFetching;

  const isDraft = currentInvoice?.status === "draft";
  const isDelivered = currentInvoice?.status === "delivered";
  const isPublished = currentInvoice?.status === "published";
  const isPaidInFull = currentInvoice?.status === "paid_in_full";
  const isGenerating = currentInvoice?.asyncJob && currentInvoice?.asyncJob?.status !== "done";
  const isSummary = currentInvoice?.isSummary === true;
  const isChildInvoice = currentInvoice?.parentInvoiceId !== null;

  const handleDelete = async (record) => {
    const result = await deleteInvoiceItem({ invoiceId, invoiceItemId: record.id });
    if ("data" in result) {
      message.success("Invoice item removed successfully!");
    }
  };

  const onPaginationChange = (newPage, newPageSize) => {
    setPagination({
      pageSize: newPageSize,
      prevToken: null,
      nextToken: null,
    });
    setVirtualPage(newPage);
  };

  const handleTableChange = (newPagination, newFilters, sorter) => {
    if (newPagination && Object.keys(newPagination).length > 0) {
      setVirtualPage(newPagination.virtualPage);
      setPagination({
        pageSize: newPagination.pageSize,
        prevToken: newPagination.prevToken,
        nextToken: newPagination.nextToken,
      });
    } else {
      setPagination({
        pageSize,
        prevToken: null,
        nextToken: null,
      });
    }

    if (sorter && Object.keys(sorter).length > 0) {
      let sorterSubquery = "";
      if (sorter.order === "ascend") {
        sorterSubquery += "-";
      }

      sorterSubquery += camelToSnakeCase(sorter.column?.sortKey ?? sorter.field);

      setTableSort(sorterSubquery);
    }
  };

  const showPublishInvoiceConfirm = () => {
    let title =
      "You will not be able to edit the invoice after it is published. Are you sure you want to publish this invoice?";
    if (isSummary) {
      title = `Publishing this invoice will also publish all its children invoices. ${title}`;
    }
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await publishInvoice(invoiceId);
        if ("data" in result) {
          message.success("Invoice published successfully!");
        }
      },
      onCancel() {},
    });
  };

  const showDraftInvoiceConfirm = () => {
    let title = "Are you sure you want to revert this invoice back to draft?";
    if (isSummary) {
      title = `Reverting this invoice back to draft will also revert all its children invoices. ${title}`;
    }
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await draftInvoice(invoiceId);
        if ("data" in result) {
          message.success("Invoice reverted to draft successfully!");
        }
      },
      onCancel() {},
    });
  };

  const showDeliveredInvoiceConfirm = () => {
    let title = "Are you sure you want to mark this invoice as delivered?";
    if (isSummary) {
      title = `Marking this invoice as delivered will also mark all its children invoices as delivered. ${title}`;
    }
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await updateInvoice({
          invoiceId,
          payload: { status: "delivered" },
        });
        if ("data" in result) {
          message.success("Invoice is now delivered!");
        }
      },
      onCancel() {},
    });
  };

  const creditorName = creditor?.name;

  const dataColumns = [
    {
      title: "Account ID",
      dataIndex: "accountExternalId",
      key: "accountExternalId",
      fixed: "left",
    },
    {
      title: "Client Reference ID",
      dataIndex: "accountClientReferenceId",
      key: "accountClientReferenceId",
      render: (text, record) => record?.accountClientReferenceId || "-",
      width: 175,
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (text, record) => {
        return record.transactionDate && moment(record.transactionDate).format(DATE_FORMAT);
      },
    },
    {
      title: "Debtor",
      dataIndex: "debtorName",
      key: "debtorName",
    },
    {
      title: `Payments`,
      children: [
        {
          title: `Paid to ${agency?.name}`,
          dataIndex: "amountPaidToAgency",
          render: (text, record) =>
            record.amountPaidToAgency && formatCurrency(record.amountPaidToAgency),
          key: "amountPaidToAgency",
        },
        {
          title: `Paid to ${creditorName}`,
          dataIndex: "amountPaidToCreditor",
          render: (text, record) =>
            record.amountPaidToCreditor && formatCurrency(record.amountPaidToCreditor),
          key: "amountPaidToCreditor",
        },
        {
          title: `${agency?.name} Commission`,
          render: (text, record) => {
            if (record.amountPaidToAgency) {
              return formatCurrency(record.agencyCommissionOnAmountPaidToAgency);
            }
            return formatCurrency(record.agencyCommissionOnAmountPaidToCreditor);
          },
          key: "amountDueAgency",
        },
        {
          title: `${creditorName} Share`,
          render: (text, record) => {
            if (record.amountPaidToAgency) {
              return formatCurrency(record.creditorCommissionOnAmountPaidToAgency);
            }
            return formatCurrency(record.creditorCommissionOnAmountPaidToCreditor);
          },
          key: "amountDueCreditor",
        },
      ],
    },
    {
      title: "Fees/Adjustments",
      children: [
        {
          title: `Credits Due ${creditorName}`,
          dataIndex: "creditAmount",
          render: (text, record) => record.creditAmount && formatCurrency(record.creditAmount),
          key: "creditAmount",
        },
        {
          title: `Fees Due ${agency?.name}`,
          dataIndex: "feeAmount",
          render: (text, record) => record.feeAmount && formatCurrency(record.feeAmount),
          key: "feeAmount",
        },
      ],
    },
    {
      title: "Amount You Owe Us",
      dataIndex: "totalAmountDueToAgency",
      render: (text, record) =>
        record.totalAmountDueToAgency && formatCurrency(record.totalAmountDueToAgency),
      key: "totalAmountDueToAgency",
    },
    {
      title: "Amount We Owe You",
      dataIndex: "totalAmountDueToCreditor",
      render: (text, record) =>
        record.totalAmountDueToCreditor && formatCurrency(record.totalAmountDueToCreditor),
      key: "totalAmountDueToCreditor",
    },
    {
      title: "Current Balance",
      dataIndex: "accountTotalBalance",
      render: (text, record) =>
        record.accountTotalBalance && formatCurrency(record.accountTotalBalance),
      key: "accountTotalBalance",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  const actionColumn = {
    title: "Actions",
    fixed: "right",
    align: "center",
    dataIndex: "actions",
    key: "actions",
    render: (_, record) => {
      return (
        !isGenerating && (
          <Space size="middle">
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this item?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete-invoice-item" />
            </Popconfirm>
          </Space>
        )
      );
    },
  };

  const columns = isDraft ? [...dataColumns, actionColumn] : dataColumns;

  const summaryCell = () => {
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <StyledCell index={0}>Totals</StyledCell>
          <StyledCell index={1} />
          <StyledCell index={2} />
          <StyledCell index={3} />
          <StyledCell index={4}>
            <strong>{formatCurrency(currentInvoice?.totalAmountPaidToAgency)}</strong>
          </StyledCell>
          <StyledCell index={5}>
            <strong>{formatCurrency(currentInvoice?.totalAmountPaidToCreditor)}</strong>
          </StyledCell>
          <StyledCell index={6}>
            <strong>
              {formatCurrency(
                currency(currentInvoice?.totalAgencyCommissionOnAmountPaidToAgency).add(
                  currentInvoice?.totalAgencyCommissionOnAmountPaidToCreditor,
                ),
              )}
            </strong>
          </StyledCell>
          <StyledCell index={7}>
            <strong>
              {formatCurrency(
                currency(currentInvoice?.totalCreditorCommissionOnAmountPaidToAgency).add(
                  currentInvoice?.totalCreditorCommissionOnAmountPaidToCreditor,
                ),
              )}
            </strong>
          </StyledCell>
          <StyledCell index={8}>
            <strong>{formatCurrency(currentInvoice?.totalAmountInCredit)}</strong>
          </StyledCell>
          <StyledCell index={9}>
            <strong>{formatCurrency(currentInvoice?.totalAmountInFees)}</strong>
          </StyledCell>
          <StyledCell index={10} />
          <StyledCell index={11} />
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const summaryCellForChildrenInvoices = () => {
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <StyledCell index={0} />
          <StyledCell index={1}>{formatCurrency(currentInvoice?.totalCollection)}</StyledCell>
          <StyledCell index={2}>{formatCurrency(currentInvoice?.totalAmountInCredit)}</StyledCell>
          <StyledCell index={3}>{formatCurrency(currentInvoice?.totalAmountInFees)}</StyledCell>
          <StyledCell index={4}>{formatCurrency(currentInvoice?.totalAgencyCommission)}</StyledCell>
          <StyledCell index={5}>
            {formatCurrency(currentInvoice?.totalTaxOnAgencyCommission)}
          </StyledCell>
          <StyledCell index={6}>
            {formatCurrency(currentInvoice?.totalCreditorCommission)}
          </StyledCell>
          <StyledCell index={7}>
            <strong>{formatCurrency(currentInvoice?.summaryTotalAmountDueToAgency)}</strong>
          </StyledCell>
          <StyledCell index={8}>
            <strong>{formatCurrency(currentInvoice?.summaryTotalAmountDueToCreditor)}</strong>
          </StyledCell>
          <StyledCell index={9} />
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const getInvoiceBreadcrumbs = () => {
    const breadcrumbs = [];
    if (isChildInvoice) {
      breadcrumbs.push({
        title: (
          <StyledHeader>Parent Summary Invoice# {currentInvoice?.parentInvoiceId}</StyledHeader>
        ),
        href: `/invoices/${currentInvoice?.parentInvoiceId}`,
      });
    }
    breadcrumbs.push({
      title: (
        <StyledHeader>{currentInvoice?.name || `Invoice# ${currentInvoice?.id}`}</StyledHeader>
      ),
    });
    return breadcrumbs;
  };

  return (
    <Card>
      {isGenerating && (
        <StyledRow>
          <Col flex="auto">
            <Alert showIcon message="Invoice is being generated. Please wait..." type="info" />
          </Col>
        </StyledRow>
      )}
      <StyledRow>
        <Col flex="auto">
          <HeaderContainer>
            <Breadcrumb items={getInvoiceBreadcrumbs()} />
            {isPublished && <StyledTag color="yellow">published</StyledTag>}
            {isDraft && <StyledTag>draft</StyledTag>}
            {isDelivered && <StyledTag color="yellow">delivered</StyledTag>}
            {isPaidInFull && <StyledTag color="green">Paid</StyledTag>}
          </HeaderContainer>
          <StyledValue>Creditor: {creditorName}</StyledValue>
          <Divider type="vertical" />
          <StyledValue>
            Reporting Period: {moment(currentInvoice?.startDate).format(DATE_FORMAT)} -{" "}
            {moment(currentInvoice?.endDate).format(DATE_FORMAT)}
          </StyledValue>
          <Divider type="vertical" />
          <StyledValue>
            Created At: {moment(currentInvoice?.createdAt).format(DATE_TIME_FORMAT)}
          </StyledValue>
          <Divider type="vertical" />
          <StyledValue>
            Updated At: {moment(currentInvoice?.updatedAt).format(DATE_TIME_FORMAT)}
          </StyledValue>
          <Divider type="vertical" />
        </Col>
        <Col>
          {isDraft && !isGenerating ? (
            <Space wrap>
              {!isSummary && (
                <Button icon={<PlusOutlined />} onClick={() => setShowAddAdjustmentModal(true)}>
                  Add Adjustment
                </Button>
              )}
              <Button
                loading={isLoading}
                icon={<DownloadOutlined rotate={180} />}
                type="primary"
                onClick={showPublishInvoiceConfirm}
              >
                Publish
              </Button>
            </Space>
          ) : null}
          {isPublished && !isGenerating ? (
            <Space wrap>
              <Button
                loading={isLoading}
                icon={<DownloadOutlined rotate={180} />}
                onClick={showDraftInvoiceConfirm}
              >
                Revert To Draft
              </Button>
              <StyledDeliveredButton
                loading={isLoading}
                icon={<CheckOutlined />}
                type="primary"
                onClick={showDeliveredInvoiceConfirm}
              >
                Mark as Delivered
              </StyledDeliveredButton>
            </Space>
          ) : null}
          {(isDelivered || isPaidInFull) && !isGenerating ? (
            <Space wrap>
              <Button
                loading={isLoading}
                icon={<DownloadOutlined rotate={180} />}
                onClick={showDraftInvoiceConfirm}
              >
                Revert To Draft
              </Button>
            </Space>
          ) : null}
        </Col>
      </StyledRow>
      {!isSummary && (
        <>
          <h4>Summary</h4>
          <CursorTable
            loading={isLoading}
            bordered
            scroll={{ x: "max-content" }}
            // @ts-ignore
            columns={columns}
            dataSource={invoiceItems}
            summary={summaryCell}
            pagination={{
              pagination: onPaginationChange,
            }}
            onChange={handleTableChange}
            pageSize={pageSize}
            prevToken={prevToken}
            nextToken={nextToken}
            virtualPage={virtualPage}
            setVirtualPage={setVirtualPage}
          />
        </>
      )}
      {isSummary && (
        <>
          <h4>Children Invoices</h4>
          <Table
            loading={isLoading}
            bordered
            // @ts-ignore
            columns={childrenInvoicesColumns}
            summary={summaryCellForChildrenInvoices}
            dataSource={childrenInvoices}
            pagination={false}
          />
        </>
      )}
      <StyledDescriptions column={1} bordered>
        <StyledDescriptions.Item label={`${agency?.name} Share`}>
          <strong>{formatCurrency(currentInvoice?.totalAgencyCommission)}</strong>
        </StyledDescriptions.Item>
        <StyledDescriptions.Item label="Total Tax">
          <strong>{formatCurrency(currentInvoice?.totalTaxOnAgencyCommission)}</strong>
        </StyledDescriptions.Item>
        <StyledDescriptions.Item label={`${creditorName} Share`}>
          <strong>{formatCurrency(currentInvoice?.totalCreditorCommission)}</strong>
        </StyledDescriptions.Item>
      </StyledDescriptions>
      <h4>Final Breakdown</h4>
      <StyledDescriptions column={1} bordered>
        <StyledDescriptions.Item label="Amount You Owe Us">
          <strong>{formatCurrency(currentInvoice?.summaryTotalAmountDueToAgency)}</strong>
        </StyledDescriptions.Item>
        <StyledDescriptions.Item label="Amount We Owe You">
          <strong>{formatCurrency(currentInvoice?.summaryTotalAmountDueToCreditor)}</strong>
        </StyledDescriptions.Item>
      </StyledDescriptions>
      {isDraft && !isGenerating && (
        <AddAdjustmentModal
          title="Add Adjustment"
          open={showAddAdjustmentModal}
          onCancel={() => setShowAddAdjustmentModal(false)}
          onOk={() => setShowAddAdjustmentModal(false)}
          invoice={currentInvoice}
        />
      )}
    </Card>
  );
}

export default InvoiceDetail;
