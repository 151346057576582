import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Popover, Row, Select, Space, Switch } from "antd";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchImportHistoryQuery } from "features/dataImport/agencyPortal/dataImportAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchDebtorTagsQuery } from "features/tags/tagsAPI";

import moment from "moment-timezone";
import styled from "styled-components";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledSpan = styled.span`
  margin-bottom: 24px;
  display: block;
  font-size: 13px;
`;

const FilterTitle = styled.div`
  font-weight: 600;
  padding: 0 0 2px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-right: 4px;
`;

export function DebtorsFiltersStep() {
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const { data: debtorTags = [] } = useFetchDebtorTagsQuery();
  const { data: defaultWorkflowStates = [] } = useFetchAgencyWorkflowStates();
  const { data: productOfferings = [] } = useFetchProductOfferingsQuery();
  const { data: creditors = [] } = useFetchCreditorSummariesQuery();
  const { data: accountImports = [], isLoading: isAccountImportsLoading } =
    useFetchImportHistoryQuery({ type: "bulk_action_account", status: "success" });
  const { data: debtorImports = [], isLoading: isDebtorImportsLoading } =
    useFetchImportHistoryQuery({ type: "bulk_action_debtor", status: "success" });
  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <>
      <StyledHeader>Filters</StyledHeader>
      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <Form.Item hidden name="type" />

      <FilterTitle>
        Debtor State
        <Popover
          content="This filters by the primary address of the debtor"
          title=""
          trigger="hover"
          placement="right"
        >
          <StyledQuestionCircleOutlined />
        </Popover>
      </FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "states"]} label="Include">
            <Select
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              filterOption={filterOption}
              options={(constants.states ?? []).map((state) => ({
                value: state.value,
                label: state.display,
              }))}
              placeholder="States..."
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeStates"]} label="Exclude">
            <Select
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              filterOption={filterOption}
              options={(constants.states ?? []).map((state) => ({
                value: state.value,
                label: state.display,
              }))}
              placeholder="States..."
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          Override operating states
          <Popover
            content="If enabled, the bulk action can apply to debtors outside the operating states"
            title=""
            trigger="hover"
            placement="right"
          >
            <StyledQuestionCircleOutlined />
          </Popover>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "ignoreOperatingStates"]}>
            <Switch checkedChildren="Enable" unCheckedChildren="Disable" />
          </Form.Item>
        </Col>
      </Row>

      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <FilterTitle>Debtor Tags</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "tagIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Debtor Tags..."
              filterOption={filterOption}
              options={debtorTags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeTagIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Debtor Tags..."
              filterOption={filterOption}
              options={debtorTags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <FilterTitle>Collectors Assigned</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "assigneeIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Collectors Assigned..."
              filterOption={filterOption}
              options={
                collectors && [
                  {
                    label: "Unassigned",
                    value: null,
                  },
                  ...(collectors.map((c) => ({
                    label: collectorFullName(c),
                    value: c.id,
                  })) ?? []),
                ]
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeAssigneeIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Collectors Assigned..."
              filterOption={filterOption}
              options={
                collectors && [
                  {
                    label: "Unassigned",
                    value: null,
                  },
                  ...(collectors.map((c) => ({
                    label: collectorFullName(c),
                    value: c.id,
                  })) ?? []),
                ]
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Clients</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "creditorIds"]} label="Include">
            <StyledSelect
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Clients..."
              filterOption={filterOption}
              options={(creditors ?? []).map((creditor) => ({
                value: creditor.id,
                label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeCreditorIds"]} label="Exclude">
            <StyledSelect
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Clients..."
              filterOption={filterOption}
              options={(creditors ?? []).map((creditor) => ({
                value: creditor.id,
                label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Statuses</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "statusCodes"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Status..."
              filterOption={filterOption}
              options={defaultWorkflowStates.map((state) => ({
                label: [state.code, state.name].join(" - "),
                value: state.code,
                title: state.description,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeStatusCodes"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Status..."
              filterOption={filterOption}
              options={defaultWorkflowStates.map((state) => ({
                label: [state.code, state.name].join(" - "),
                value: state.code,
                title: state.description,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Product Offerings</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "productOfferingIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Product Offering..."
              filterOption={filterOption}
              options={productOfferings.map((productOffering) => ({
                label: productOffering.name,
                value: productOffering.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeProductOfferingIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Product Offering..."
              filterOption={filterOption}
              options={productOfferings.map((productOffering) => ({
                label: productOffering.name,
                value: productOffering.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Account Imports</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "accountDataImportIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Account Imports..."
              filterOption={filterOption}
              options={accountImports.map((accountImport) => ({
                value: accountImport.id,
                label: `${accountImport.filename} - ${moment(accountImport.createdAt).format(
                  "MM/DD/YY HH:mm",
                )}`,
              }))}
              disabled={isAccountImportsLoading}
              loading={isAccountImportsLoading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeAccountDataImportIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Account Imports..."
              filterOption={filterOption}
              options={accountImports.map((accountImport) => ({
                value: accountImport.id,
                label: `${accountImport.filename} - ${moment(accountImport.createdAt).format(
                  "MM/DD/YY HH:mm",
                )}`,
              }))}
              disabled={isAccountImportsLoading}
              loading={isAccountImportsLoading}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Debtor Imports</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "debtorDataImportIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Debtor Imports..."
              filterOption={filterOption}
              options={debtorImports.map((debtorImport) => ({
                value: debtorImport.id,
                label: `${debtorImport.filename} - ${moment(debtorImport.createdAt).format(
                  "MM/DD/YY HH:mm",
                )}`,
              }))}
              disabled={isDebtorImportsLoading}
              loading={isDebtorImportsLoading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeDebtorDataImportIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Debtor Imports..."
              filterOption={filterOption}
              options={debtorImports.map((debtorImport) => ({
                value: debtorImport.id,
                label: `${debtorImport.filename} - ${moment(debtorImport.createdAt).format(
                  "MM/DD/YY HH:mm",
                )}`,
              }))}
              disabled={isDebtorImportsLoading}
              loading={isDebtorImportsLoading}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>CBR Class Codes</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "includeCbrClassCodes"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Include CBR Class Codes..."
              filterOption={filterOption}
              // map options from constants.cbrClassCodes
              options={(constants.cbrClassCodes ?? []).map((cbrClassCode) => ({
                label: cbrClassCode.display,
                value: cbrClassCode.value,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeCbrClassCodes"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Exclude CBR Class Codes..."
              filterOption={filterOption}
              options={(constants.cbrClassCodes ?? []).map((cbrClassCode) => ({
                label: cbrClassCode.display,
                value: cbrClassCode.value,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Turnover Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "turnoverDate"]} label="Include">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeTurnoverDate"]} label="Exclude">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <FilterTitle>Follow-up Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "followUpDate"]} label="Include">
            <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeFollowUpDate"]} label="Exclude">
            <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <FilterTitle>Last Call Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "lastCallDate"]} label="Include">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeLastCallDate"]} label="Exclude">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Last Payment Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "lastPaymentDate"]} label="Include">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeLastPaymentDate"]} label="Exclude">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Total Balance:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minAccountBalance"]}>
          <InputNumber placeholder="Min" controls={false} min="0" prefix="$" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxAccountBalance"]}>
          <InputNumber placeholder="Max" controls={false} min="0" prefix="$" step="0.01" />
        </Form.Item>
      </Space>

      <FilterTitle>Score:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minExternalScore"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxExternalScore"]}>
          <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
        </Form.Item>
      </Space>

      <FilterTitle>Intelitech I-Score:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minIntelitechIScore"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="1" max="999" />
        </Form.Item>
        <Form.Item name={["filters", "maxIntelitechIScore"]}>
          <InputNumber placeholder="Max" controls={false} min="0" max="999" step="1" />
        </Form.Item>
      </Space>

      <FilterTitle>Intelitech Medal-Score:</FilterTitle>
      <Form.Item name={["filters", "intelitechMedalScore"]}>
        <StyledSelect
          allowClear
          mode="multiple"
          placeholder="Select one..."
          popupMatchSelectWidth={false}
          options={constants?.intelitechMedalScores ?? []}
        />
      </Form.Item>

      <FilterTitle>Intelitech Q-Score:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minIntelitechQScore"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxIntelitechQScore"]}>
          <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
        </Form.Item>
      </Space>

      <FilterTitle>Intelitech R-Score:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minIntelitechRScore"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxIntelitechRScore"]}>
          <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
        </Form.Item>
      </Space>

      <FilterTitle>Intelitech FPG-Value:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "intelitechFpgValue"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "intelitechFpgValue"]}>
          <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
        </Form.Item>
      </Space>
    </>
  );
}
