import { Form, InputNumber, Radio, Select } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

export default function CreditReportingStep() {
  const { data: { cbrHoldDateTypes = [] } = {} } = useFetchBackendConstantsQuery();

  return (
    <>
      <Form.Item
        name={["creditReportingConfig", "isEnabled"]}
        label="Run Credit Reporting"
        tooltip="Does this creditor want credit reporting for their accounts?"
      >
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "minBalance"]}
        label="Minimum Account Balance Required to Credit Report"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "maxBalance"]}
        label="Maximum Account Balance Required to Send - (Coming Soon)"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "includeInterest"]}
        label="Report Interest to Credit Bureau"
      >
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "includeFees"]}
        label="Report Fees to Credit Bureau"
      >
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "startDate"]}
        label="Report Accounts Start Date - (Coming Soon)"
        tooltip="The date the AGENCY starts reporting accounts."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "daysToHoldType"]}
        label="Credit Bureau Days To Hold Type [Primary]"
      >
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select a type"
          options={cbrHoldDateTypes.map((each) => ({
            label: each.display,
            value: each.value,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "daysToHold"]}
        label="# of Days to Hold From Credit Bureau [Primary]"
        tooltip="How many days before an account can be reported (starting from the selected days to hold type)?"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "secondaryDaysToHoldType"]}
        label="Credit Bureau Days To Hold Type [Secondary]"
      >
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select a type"
          options={cbrHoldDateTypes.map((each) => ({
            label: each.display,
            value: each.value,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "secondaryDaysToHold"]}
        label="# of Days to Hold From Credit Bureau [Secondary]"
        tooltip="How many days before an account can be reported (starting from the selected days to hold type)?"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "reporterType"]}
        label="Report to Credit Bureau As"
      >
        <Select
          placeholder="Select a type"
          options={[
            {
              label: "Collection Agency",
              value: "collection_agency",
            },
            {
              label: "Traditional Bank",
              value: "traditional_bank",
            },
            {
              label: "Digital Bank",
              value: "digital_bank",
            },
            {
              label: "Credit Union",
              value: "credit_union",
            },
            {
              label: "Credit Card Issuer",
              value: "credit_card_issuer",
            },
            {
              label: "Mortgage Lender",
              value: "mortgage_lender",
            },
            {
              label: "Auto Loan Lender",
              value: "auto_loan_lender",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name={["creditReportingConfig", "paidInFullAccountStatusCode"]}
        label="Report Accounts Paid in Full / Settled in Full As"
      >
        <Select
          popupMatchSelectWidth={false}
          placeholder="Select a type"
          options={[
            {
              label: "Delete Account",
              value: "DA",
            },
            {
              label: "Paid in Full",
              value: "62",
            },
          ]}
        />
      </Form.Item>
    </>
  );
}
