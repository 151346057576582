import { CheckCircleOutlined, ClockCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Modal, Tag, Timeline, Tooltip, message } from "antd";
import { DATE_FORMAT } from "common/constants";
import { useUserType } from "features/auth";
import {
  useFetchSequenceTasksQuery,
  useUpdateAccountMutation,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  margin-left: 5px;
`;

function AccountSequencesModal({ title, open, onOk, onCancel, account, debtorId }) {
  const { isCreditorUserType } = useUserType();
  const { data: sequenceTasks } = useFetchSequenceTasksQuery(
    {
      page_size: 1000,
      filters: {
        debtorId: parseInt(debtorId, 10),
        accountId: account?.id,
      },
    },
    {
      skip: !account?.id || !debtorId,
    },
  );
  const [updateAccount, { isLoading }] = useUpdateAccountMutation();

  const determineColor = (task) => {
    if (task.executedAt === null && !account?.isCommunicationSequenceEnabled) {
      return "red";
    }
    if (task.executedAt === null) {
      return "blue";
    }
    if (task.executedAt) {
      return "green";
    }
  };

  const determineDot = (task) => {
    if (task.isCurrent && !account?.isCommunicationSequenceEnabled) {
      return (
        <Tooltip title="Stopped executing">
          <MinusCircleOutlined />
        </Tooltip>
      );
    }
    if (task.isCurrent) {
      return (
        <Tooltip title="Currently executing">
          <ClockCircleOutlined />
        </Tooltip>
      );
    }
    if (task.executedAt) {
      return (
        <Tooltip title="Done executing">
          <CheckCircleOutlined />
        </Tooltip>
      );
    }
  };

  const handleTerminate = async () => {
    const result = await updateAccount({
      accountId: account?.id,
      isCommunicationSequenceEnabled: false,
    });
    if ("data" in result) {
      message.success("Communication sequence stopped successfully!");
      onOk();
    }
    if ("error" in result) {
      message.error("Failed to terminate communication sequence");
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={
        account?.isCommunicationSequenceEnabled ? (
          title
        ) : (
          <>
            {title}
            <StyledTag color="red">TERMINATED</StyledTag>
          </>
        )
      }
      open={open}
      okButtonProps={{
        disabled: !account?.isCommunicationSequenceEnabled || isCreditorUserType,
        loading: isLoading,
      }}
      onOk={handleTerminate}
      okText="Terminate"
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Timeline
        items={sequenceTasks?.map((task) => ({
          color: determineColor(task),
          dot: determineDot(task),
          children: (
            <>
              <p>
                <strong>{task.step?.name}</strong>
                {task.isCurrent ? <i> (current)</i> : null}
              </p>
              {task.scheduledDate ? (
                <p>Scheduled for {moment(task.scheduledDate).format(DATE_FORMAT)}</p>
              ) : null}
              {task.executedAt ? (
                <p>Executed on {moment(task.executedAt).format(DATE_FORMAT)}</p>
              ) : null}
            </>
          ),
        }))}
      />
    </Modal>
  );
}

export default AccountSequencesModal;
